import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { HTTP_STATUS_CODE, getRedirectDomainHR, getDomainHR } from './config';
import { notification } from 'antd';

const host: string = location.host;

const request = axios.create({
  baseURL: getDomainHR(host),
  timeout: 10000,
  withCredentials: true,
});

const addAuthorizationHeader = async (config: AxiosRequestConfig) => {
  const token = await window.localStorage.getItem('session');
  if (token) {
    config.headers = {
      ...config.headers,
      Authorization: token,
      'Content-Type': 'application/json;charset=UTF-8;',
    };
  }
  return config;
};

const handleErrorResponse = (error: AxiosError) => {
  if (error.response?.status !== HTTP_STATUS_CODE.OK) {
    const data = error.response?.data;
    notification.error({ message: data?.message });
  }
  if (error.response?.status === HTTP_STATUS_CODE.UNAUTHORIZED) {
    window.localStorage.removeItem('session');
    window.location.href = getRedirectDomainHR(host);
  }
  return Promise.reject(error.response);
};

request.interceptors.request.use(addAuthorizationHeader);
request.interceptors.response.use((response) => response, handleErrorResponse);

export default request;
