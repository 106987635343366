/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUsers, getUserById } from '@app/api/users.api';
import { initialState } from '@app/model/users/initial';
import { ParamsRequest } from '@app/model/users/interface';

export const getListUsers = createAsyncThunk('users/getListUsers', async (params: ParamsRequest) =>
  getUsers(params).then((res: any) => {
    return res;
  }),
);

export const getUserDetail = createAsyncThunk('users/getUserDetail', async (id: string) =>
  getUserById(id).then((res: any) => {
    return res;
  }),
);

export const setUserDetail = createAsyncThunk('users/setUserDetail', async (id: string) =>
  getUserById(id).then((res: any) => {
    return res;
  }),
);

export const doSetUserDetail = createAsyncThunk('auth/doSetUserDetail', async (userPayload: any) => {
  setUserDetail(userPayload);
});

const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getListUsers.fulfilled, (state, action) => {
      state.data = action.payload.users;
      state.total = action.payload.total;
      state.isChange = false;
    });
    builder.addCase(getUserDetail.fulfilled, (state, action) => {
      state.userInfo = action.payload.userInfo;
    });
    builder.addCase(doSetUserDetail.fulfilled, (state, action) => {
      const userInfo = action.meta.arg;
      state.userInfo = userInfo;
      state.isChange = true;
    });
  },
});

export default usersSlice.reducer;
