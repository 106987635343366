import React from 'react';
import * as S from './ProfileOverlay.styles';

import { useTranslation } from 'react-i18next';
import { getRedirectDomainHR } from '@app/config/config';

export const ProfileOverlay: React.FC = ({ ...props }) => {
  const { t } = useTranslation();

  const handleLogout = () => {
    const host: string = location.host;
    localStorage.removeItem('session');
    window.location.href = getRedirectDomainHR(host);
  };

  return (
    <div {...props}>
      <S.Text>
        <div
          onClick={() => {
            handleLogout();
          }}
        >
          {t('header.logout')}
        </div>
      </S.Text>
    </div>
  );
};
