/* eslint-disable @typescript-eslint/no-explicit-any */
import './mocks/auth.api.mock';
import KidoAPIInstance from '@app/config/request';

export const getCurrentInfoUser: any = async () => {
  return await KidoAPIInstance(`/api/currentUser`).then(({ data }) => {
    return data.data;
  });
};

export const getModelTrainingStatus: any = async () => {
  return await KidoAPIInstance(`/api/user/getModelTrainingStatus`).then(({ data }) => {
    return data.data;
  });
};
