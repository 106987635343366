/* eslint-disable @typescript-eslint/no-explicit-any */
import './mocks/auth.api.mock';
import KidoAPIInstance from '@app/config/request';
import { ParamsRequest } from '@app/model/users/interface';

export const getUsers: any = async (params: ParamsRequest) => {
  return await KidoAPIInstance('/api/user/list', {
    method: 'GET',
    params,
  }).then(({ data }) => {
    return data.data;
  });
};

export const getUserById: any = async (id: string) => {
  return await KidoAPIInstance(`/api/user/detail?id=${id}`).then(({ data }) => {
    return data.data;
  });
};
