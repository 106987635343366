/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import * as S from './MainSider.styles';
import SiderMenu from '../SiderMenu/SiderMenu';

import { MenuUnfoldOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { Overlay } from '../../../../common/Overlay/Overlay';
import { useResponsive } from 'hooks/useResponsive';
import { SiderLogo } from '../SiderLogo';

interface MainSiderProps {
  isCollapsed: boolean;
  setCollapsed: (isCollapsed: boolean) => void;
}

const MainSider: React.FC<MainSiderProps> = ({ isCollapsed, setCollapsed, ...props }) => {
  const { isDesktop, mobileOnly, tabletOnly } = useResponsive();

  useEffect(() => {
    if (isDesktop) {
      setCollapsed(isDesktop);
    }
    if (mobileOnly || tabletOnly) {
      setCollapsed(false);
    }
  }, [isDesktop, mobileOnly, tabletOnly]);

  const toggleSider = () => setCollapsed(!isCollapsed);

  return (
    <React.Fragment>
      <S.Sider
        trigger={null}
        collapsed={!isCollapsed}
        collapsedWidth={!mobileOnly ? 70 : 0}
        collapsible={isCollapsed}
        width={260}
        {...props}
      >
        <SiderLogo />
        <S.SiderContent>
          <SiderMenu setCollapsed={setCollapsed} />
        </S.SiderContent>
        <S.SiderFooter onClick={toggleSider}>
          {isCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </S.SiderFooter>
      </S.Sider>
      {mobileOnly && <Overlay onClick={toggleSider} show={isCollapsed} />}
    </React.Fragment>
  );
};

export default MainSider;
