/* eslint-disable prettier/prettier */
import styled from 'styled-components';
import { ClusterOutlined } from '@ant-design/icons';
import { BASE_COLORS } from '@app/styles/themes/constants';
import { BaseButton as BaseButton, BaseProgress } from '@app/components/common';

export const Button = styled(BaseButton)<{ $isDark: boolean }>`
  color: ${(props) => BASE_COLORS[props.$isDark ? 'white' : 'black']};
  border-radius: 12px;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  margin-left: 40px;
`;

export const ClusterOutIcon = styled(ClusterOutlined)`
  font-size: 1.2rem;
  vertical-align: middle;
`;

export const Progress = styled(BaseProgress)`
  .ant-progress-outer .ant-progress-inner {
    background-color: #ebebeb;
  }
`;

export const NotiLoading = styled.div<{ $isDark: boolean }>`
  position: absolute;
  top: 40px;
  z-index: 9;
  background: ${(props) => (props.$isDark ? 'var(--layout-header-bg-color)}' : '#f8fbff')};
  padding: 0px 20px 10px;
  box-shadow: 0px 20px 19px 0px rgba(0, 0, 0, 0.07);
  border-radius: 0px 0px 7px 7px;
`;
