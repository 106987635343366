/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState, useRef } from 'react';
import KidoAPIInstance from '@app/config/request';
import * as GitHubButtonStyle from './GitHubButton.style';

import { useAppSelector } from '@app/hooks/reduxHooks';
import { notificationController } from '@app/controllers/notificationController';
import { setProgressTraining, clearProgressTraining } from '@app/store/slices/userSlice';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getPortSocket } from '@app/config/config';

export const GitHubButton: React.FC = (props) => {
  const host: string = window.location.host;
  const SERVER_URL = getPortSocket(host);
  const theme = useAppSelector((state) => state.theme.theme);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const dataProgressRef = useRef<any>(null);

  const isTrainingStatus = useAppSelector((state) => state.user.isTrainingStatus);
  const currentUser = useAppSelector((state) => state.user.currentUser);
  const dataProgress = useAppSelector((state) => state.user.dataProgress);

  useEffect(() => {
    const socket = new WebSocket(SERVER_URL);
    socket.onmessage = (event) => {
      if (event.data.includes('PROCESS_TRAINING')) {
        const inputString = event.data;
        const parts = inputString.split('_');
        const title = parts[parts.length - 2];
        const process = Math.round(Number(parts[parts.length - 1]) * 100) / 100;
        if (process >= 100) {
          setLoading(false);
        } else {
          setLoading(true);
        }
        const data = {
          title,
          process: process,
        };
        dispatch(setProgressTraining(data));
        if (process == 100) {
          const data = {
            title,
            process: 101,
          };
          setTimeout(() => {
            dispatch(setProgressTraining(data));
          }, 1000);
        }
      }
    };
    return () => {
      socket.close();
    };
  }, []);

  useEffect(() => {
    if (dataProgress.length > 0) {
      dataProgressRef.current = dataProgress;
      const interval = setInterval(() => {
        const currentValue = dataProgressRef.current;
        if (currentValue !== null) {
          notificationController.error({ message: 'Training thất bại!' });
          dispatch(clearProgressTraining());
        }
      }, 600000); //10 phút remove;
      return () => clearInterval(interval);
    }
  }, [dataProgress]);

  const handleTrain = async () => {
    setLoading(true);
    dispatch(clearProgressTraining());
    const resp: any = await KidoAPIInstance('api/user/trainModel', {
      method: 'POST',
    }).then(({ data }) => {
      return data;
    });
    try {
      if (resp.code !== 0) {
        dispatch(clearProgressTraining());
        notificationController.error({ message: resp?.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {currentUser.refType === 'SUPER_ADMIN' && (
        <GitHubButtonStyle.Button
          type="default"
          size="small"
          style={{ padding: '0px 20px' }}
          disabled={!isTrainingStatus}
          loading={dataProgress.length > 0 || loading}
          icon={<GitHubButtonStyle.ClusterOutIcon />}
          target="_blank"
          onClick={() => {
            handleTrain();
          }}
          $isDark={theme === 'dark'}
          {...props}
        >
          Training AI
        </GitHubButtonStyle.Button>
      )}
      {dataProgress.length > 0 && currentUser.refType !== 'SUPER_ADMIN' && <div>Training AI</div>}
      <GitHubButtonStyle.NotiLoading $isDark={theme === 'dark'}>
        {dataProgress?.map((item: any, index) => {
          if (item.show) {
            return (
              <div style={{ width: 370, display: 'flex', alignItems: 'center' }} key={index}>
                <div style={{ width: 100, textAlign: 'left', fontSize: 13 }}>{item.title} :</div>
                <GitHubButtonStyle.Progress
                  percent={item.value}
                  status="active"
                  strokeColor={{ from: '#108ee9', to: '#87d068' }}
                  style={{ width: 250 }}
                />
              </div>
            );
          }
        })}
      </GitHubButtonStyle.NotiLoading>
    </div>
  );
};
