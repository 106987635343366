/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { notification } from 'antd';

export const KidoAPIInstance = axios.create({
  baseURL: 'https://hr.kdc.vn',
  timeout: 10000,
  withCredentials: true,
});

const addAuthorizationHeader = (config: any) => {
  config.headers = {
    ...config.headers,
    Authorization: 'recognitionsid=facerecognition.5.5fdb464761dc42dd5563746258253f90eda13c3c44b2fbfedcc2cd52753a1632',
    'Content-Type': 'application/json;charset=UTF-8;',
  };
  return config;
};

const handleErrorResponse = (error: any) => {
  if (error?.response?.status !== 200) {
    const data = error.response.data;
    notification.error({ message: data?.message });
  }
  if (error.response.status === 403) {
    if (typeof window !== 'undefined') {
      window.localStorage.removeItem('auth');
    }
    setTimeout(() => {
      window.location.href = encodeURI(location.href);
    }, 1000);
  }
  return Promise.reject(error.response);
};
KidoAPIInstance.interceptors.request.use(addAuthorizationHeader);
KidoAPIInstance.interceptors.response.use((response) => response, handleErrorResponse);
export default KidoAPIInstance;
