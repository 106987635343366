import React from 'react';
import moment from 'moment';
import * as S from './References.styles';

export const References: React.FC = () => {
  return (
    <S.ReferencesWrapper>
      <S.Text> &copy; {moment().format('YYYY')} CÔNG TY CỔ PHẦN TẬP ĐOÀN KIDO </S.Text>
    </S.ReferencesWrapper>
  );
};
