import { UsersSlice, Pagination, IUserInfo } from '../interface';

export const initialUserinfo: IUserInfo = {
  code: '',
  displayName: '',
  id: 0,
  image: '',
  images: [],
  address: '',
  birthday: 0,
  email: '',
  gender: 'MALE',
  maritalStatus: 'SINGLE',
  phone: '',
  taxCode: '',
};

export const initialState: UsersSlice = {
  loading: false,
  data: [],
  total: 0,
  userInfo: initialUserinfo,
  isChange: false,
};

export const initialPagination: Pagination = {
  current: 1,
  pageSize: 10,
};
