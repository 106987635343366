/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAction, createSlice, PrepareAction, createAsyncThunk } from '@reduxjs/toolkit';
import { UserModel, CurrentUser } from '@app/domain/UserModel';
import { getCurrentInfoUser, getModelTrainingStatus } from '@app/api/user.api';
import { persistUser, readUser } from '@app/services/localStorage.service';

interface IDataProgress {
  title: string;
  value: number;
  show: boolean;
}
export interface UserState {
  user: UserModel | null;
  currentUser: CurrentUser;
  isTrainingStatus: boolean;
  dataProgress: IDataProgress[];
}

const initialState: UserState = {
  user: readUser(),
  currentUser: {
    name: '',
    userId: 0,
    refType: '',
  },
  isTrainingStatus: true,
  dataProgress: [],
};

export const getCurrentUser = createAsyncThunk('user/getCurrentUser', async () =>
  getCurrentInfoUser().then((res: any) => {
    return res;
  }),
);

export const doGetModelTrainingStatus = createAsyncThunk('user/getModelTrainingStatus', async () =>
  getModelTrainingStatus().then((res: any) => {
    return res;
  }),
);

export const setUser = createAction<PrepareAction<UserModel>>('user/setUser', (newUser) => {
  persistUser(newUser);
  return {
    payload: newUser,
  };
});

interface IProgressTraining {
  title: string;
  process: number;
}

export const setProgressTraining = createAsyncThunk('user/setProgressTraining', async (data: IProgressTraining) => {
  const progressTraining = localStorage.getItem('progressTraining');
  let parsedProgressTraining = progressTraining !== null ? JSON.parse(progressTraining) : [];
  const payload = {
    title: data.title,
    value: data.process,
    show: data.process > 100 ? false : true,
  };
  if (parsedProgressTraining?.length === 0) {
    parsedProgressTraining.push(payload);
  } else {
    const condition = parsedProgressTraining?.some((item: IDataProgress) => item.title === payload.title);
    if (!condition) {
      parsedProgressTraining.push(payload);
    } else {
      parsedProgressTraining = parsedProgressTraining.map((item: IDataProgress) => {
        if (item.title === payload.title) {
          return {
            title: item.title,
            value: payload.value,
            show: payload.value > 100 ? false : true,
          };
        }
        return item;
      });
    }
  }
  parsedProgressTraining = parsedProgressTraining?.filter((item: IDataProgress) => item?.value <= 100);
  if (parsedProgressTraining?.length === 0) {
    doGetModelTrainingStatus();
  }
  return parsedProgressTraining;
});

export const clearProgressTraining = createAsyncThunk('user/clearProgressTraining', async () => {
  return [];
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setUser, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(getCurrentUser.fulfilled, (state, action) => {
      state.currentUser = action.payload;
    });
    builder.addCase(doGetModelTrainingStatus.fulfilled, (state, action) => {
      state.isTrainingStatus = action.payload.enableModelTraining;
    });
    builder.addCase(setProgressTraining.fulfilled, (state, action) => {
      state.dataProgress = action.payload;
    });
    builder.addCase(clearProgressTraining.fulfilled, (state, action) => {
      state.dataProgress = action.payload;
    });
  },
});

export default userSlice.reducer;
