/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import MainSider from '../sider/MainSider/MainSider';
import MainContent from '../MainContent/MainContent';
import * as S from './MainLayout.styles';

import { Header } from '../../../header/Header';
import { MainHeader } from '../MainHeader/MainHeader';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH } from '@app/components/router/AppRouter';
import { useResponsive } from '@app/hooks/useResponsive';
import { References } from '@app/components/common/References/References';
import { getRedirectDomainHR } from '@app/config/config';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getCurrentUser, doGetModelTrainingStatus } from '@app/store/slices/userSlice';

const MainLayout: React.FC = () => {
  const [isTwoColumnsLayout, setIsTwoColumnsLayout] = useState(true);
  const [siderCollapsed, setSiderCollapsed] = useState(true);
  const { isDesktop } = useResponsive();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.pathname === '/') {
      navigate('/timekeeping');
    }
  }, [location]);

  useEffect(() => {
    dispatch(getCurrentUser());
    dispatch(doGetModelTrainingStatus());
  }, []);

  useEffect(() => {
    if (localStorage.getItem('session')) {
      return;
    } else {
      const params = new URLSearchParams(window.location.search);
      const session = params.get('session');
      const host: string = window.location.host;

      if (!!session) {
        localStorage.setItem('session', session);
        window.location.href = `${window.location.href}`;
        return;
      } else {
        localStorage.removeItem('session');
        window.location.href = getRedirectDomainHR(host);
      }
    }
  }, [location.search]);

  const toggleSider = () => setSiderCollapsed(!siderCollapsed);

  useEffect(() => {
    setIsTwoColumnsLayout([MEDICAL_DASHBOARD_PATH, NFT_DASHBOARD_PATH].includes(location.pathname) && isDesktop);
  }, [location.pathname, isDesktop]);

  return (
    <S.LayoutMaster>
      <MainSider isCollapsed={siderCollapsed} setCollapsed={setSiderCollapsed} />
      <S.LayoutMain>
        <MainHeader isTwoColumnsLayout={isTwoColumnsLayout}>
          <Header toggleSider={toggleSider} isSiderOpened={!siderCollapsed} isTwoColumnsLayout={isTwoColumnsLayout} />
        </MainHeader>
        <MainContent id="main-content" $isTwoColumnsLayout={isTwoColumnsLayout}>
          <div>
            <Outlet />
          </div>
          {!isTwoColumnsLayout && <References />}
        </MainContent>
      </S.LayoutMain>
    </S.LayoutMaster>
  );
};

export default MainLayout;
