import React from 'react';
import * as S from './MainSider/MainSider.styles';
import logo from 'assets/logo.png';

export const SiderLogo: React.FC = () => {
  const img = logo;

  return (
    <S.SiderLogoDiv>
      <S.SiderLogoLink to="/timekeeping">
        <img src={img} alt="Nhân sự" width={36} height={36} />
        <S.BrandSpan>Nhân sự</S.BrandSpan>
      </S.SiderLogoLink>
    </S.SiderLogoDiv>
  );
};
