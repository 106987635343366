/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { BaseRow, BaseCol, PageTitle } from '@app/components/common';
import { useAppDispatch } from '@app/hooks/reduxHooks';
import { getUserDetail } from '@app/store/slices/usersSlice';

const ProfileLayout: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams();
  const id = params?.id;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getUserDetail(id));
    }
  }, [id]);

  return (
    <React.Fragment>
      <PageTitle>{t('profile.title')}</PageTitle>
      <BaseRow gutter={[30, 30]}>
        <BaseCol xs={24}>
          <Outlet />
        </BaseCol>
      </BaseRow>
    </React.Fragment>
  );
};

export default ProfileLayout;
